import request from '@/utils/request'
import Api from '@/api/base/api-config'
// 线索跟进 - 今日待跟进
export const leadsTodayList = params => {
  return request({
    method: 'get',
    url: Api.follow.leads.todayList,
    params
  })
}
// 线索跟进 - 待联系
export const leadsWaitContactList = params => {
  return request({
    method: 'get',
    url: Api.follow.leads.waitContactList,
    params
  })
}
// 线索跟进- 今日待联系
export const leadsDormantList = params => {
  return request({
    method: 'get',
    url: Api.follow.leads.dormantList,
    params
  })
}
// 商机跟进 - 今日待跟进
export const oppTodayalist = params => {
  return request({
    method: 'get',
    url: Api.follow.ledasopp.todayList,
    params
  })
}
// 商机跟进 - closedata
export const oppCloseDate = params => {
  return request({
    method: 'get',
    url: Api.follow.ledasopp.closeDateList,
    params
  })
}
// 商机跟进 - 89天未成交
export const oppUnorderList = params => {
  return request({
    method: 'get',
    url: Api.follow.ledasopp.unorderList,
    params
  })
}
// 商机跟进 - 休眠
export const hibernateList = params => {
  return request({
    method: 'get',
    url: Api.follow.ledasopp.hibernateList,
    params
  })
}
// 订单跟进 - 大定已付
export const depositPaidList = params => {
  return request({
    method: 'get',
    url: Api.follow.order.depositPaidList,
    params
  })
}
// 订单跟进 - hand-off
export const handOffList = params => {
  return request({
    method: 'get',
    url: Api.follow.order.handOffList,
    params
  })
}
// 订单跟进 - 意向金退款
export const drRefundList = params => {
  return request({
    method: 'get',
    url: Api.follow.order.drRefundList,
    params
  })
}
// 试驾跟进- 今日待跟进
export const testdrtveTodayList = params => {
  return request({
    method: 'get',
    url: Api.follow.testdrive.todayList,
    params
  })
}
// 试驾跟进 - PDC
export const pdcList = params => {
  return request({
    method: 'get',
    url: Api.follow.testdrive.pdcList,
    params
  })
}
// 试驾跟进 - PDC完成任务
export const pdcFinish = params => {
  return request({
    method: 'post',
    url: `${Api.follow.testdrive.pdcList}/${params}`
  })
}
// 试驾跟进 - No-show
export const noShowList = params => {
  return request({
    method: 'get',
    url: Api.follow.testdrive.noShowList,
    params
  })
}
// 试驾跟进 - follow-up
export const tdFollowUpList = params => {
  return request({
    method: 'get',
    url: Api.follow.testdrive.tdFollowUpList,
    params
  })
}
// 客户跟进 - 客户动向
export const endList = params => {
  return request({
    method: 'get',
    url: Api.follow.account.endList,
    params
  })
}
// 客户跟进 - 未进店
export const notToShopList = params => {
  return request({
    method: 'get',
    url: Api.follow.account.notToShopList,
    params
  })
}
// 客户跟进 - 完成任务
export const finishClient = params => {
  return request({
    method: 'put',
    url: `${Api.follow.account.finishClient}?trendsid=${params.trendsid}`
  })
}
// 勘测跟进 - 勘测终止
export const breakOffList = params => {
  return request({
    method: 'get',
    url: Api.follow.survey.breakOffList,
    params
  })
}